import { NO_KYC_CATEGORY_ID } from "constants/insights";

import { instance } from "./instances/coindisco";

export interface FetchInsightsOption {
  cryptocurrency: Coin["id"];
  currency: Currency["id"];
  currencyAmount?: number;
  network: Network["id"];
  paymentMethod: PaymentMethod["id"];
  region: Region["id"];
}

interface FetchInsightQuote {
  insightId: Insight["id"];
  baseCurrency: Currency["id"];
  amount: number;
}

export const insightsAPI = new (class {
  public async fetchCategories() {
    const { data } = await instance.get<InsightCategory[]>(
      "insights/v2/categories/",
    );

    return data;
  }

  public async fetchNoKYCCategory(args: FetchInsightsOption[]) {
    const { data } = await instance.post<Insight[]>(
      `insights/categories/${NO_KYC_CATEGORY_ID}/insights/`,
      args,
    );

    return data;
  }

  public async fetchInsights(args: FetchInsightsOption[]) {
    const { data } = await instance.post<Insight[]>(
      "insights/categories/insights/",
      args,
    );

    return data;
  }

  public async fetchInsight(id: Insight["id"]) {
    const { data } = await instance.get<Insight>(
      `insights/categories/insights/${id}/`,
    );

    return data;
  }

  public async fetchInsightQuote({
    amount,
    baseCurrency,
    insightId,
  }: FetchInsightQuote) {
    const { data } = await instance.post<BuyProvider>(
      `service-integration/search-buy-options/insight/${insightId}/`,
      {
        baseCurrency,
        currencyAmount: amount,
      },
    );

    return data;
  }
})();
