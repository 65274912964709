export enum QuoteRanges {
  "1D" = "1D",
  "1W" = "1W",
  "1M" = "1M",
  "1Y" = "1Y",
  "All" = "All",
}

export const QUOTE_RANGES_LIST = Object.values(QuoteRanges);

export const DEFAULT_QUOTE_RANGE = QuoteRanges["1D"];

export const MAX_FAVORITES_COUNT = 100;

export const BITCOIN_LETTER_ID = "btc";
export const BITCOIN_NETWORK_ID = 8;
