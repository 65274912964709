import { NetworkError } from "operations/error";
import { createAxiosInstance } from "operations/network";

const commonConfig = {
  baseURL: "https://api-staging.coindisco.com/api",
};

/**
 * Axios instance for authorized requests.
 */
export const authInstance = createAxiosInstance(commonConfig);

/**
 * Axios instance for public requests only.
 */
export const instance = createAxiosInstance(commonConfig);

instance.interceptors.response.use(
  response => response,
  error => Promise.reject(new NetworkError(error)),
);
