import { Currencies } from "constants/currencies";
import { DEFAULT_QUOTE_RANGE, QuoteRanges } from "constants/coins";

import { CoinsState } from "store/coins/duck/types";

import { authInstance, instance } from "./instances/coindisco";

interface FetchCoinsArgs {
  search?: string;
  ids?: CoinsState["ids"];
  includedIds?: CoinsState["ids"];
  favorites?: "" | "true";
  ordering?: CoinsSort;
  category?: string;
}

export interface FetchCoinsReturn {
  results: Coin[];
  isNextCoins: boolean;
}

interface Price {
  cryptocurrencyId: number;
  percentChange24H: string;
  price: string;
}

interface FetchQuotesArgs {
  id?: Coin["id"];
  range?: QuoteRanges;
}

interface QuoteDTO {
  lastUpdated: string;
  price: string;
}

const coinsAPI = new (class {
  public async fetchCategories() {
    const { data } = await instance.options<{ categories: Category[] }>(
      "market/v3/cryptocurrency/",
    );

    return data.categories;
  }

  public async fetchCoins({
    ordering = "rank",
    ids = [],
    includedIds = [],
    ...restArgs
  }: FetchCoinsArgs = {}): Promise<FetchCoinsReturn> {
    const { data } = await authInstance.post("market/v3/cryptocurrency/", {
      ordering,
      pageSize: 100,
      cryptocurrencies: ids,
      includeCryptocurrencies: includedIds,
      ...restArgs,
    });

    return {
      results: data.results,
      isNextCoins: Boolean(data.next),
    };
  }

  public async fetchCoin(letterId: Coin["letterId"]) {
    const { data } = await authInstance.get<Coin>(
      `market/web/v3/cryptocurrency/${letterId}/`,
    );

    return data;
  }

  public async fetchLightCoin(letterId: Coin["letterId"]) {
    const { data } = await authInstance.get<BaseCoin>(
      `market/web/v3/cryptocurrency/light/${letterId}/`,
    );

    return data;
  }

  public async fetchPrices() {
    const { data } = await instance.get<Price[]>(
      `market/v2/cryptocurrency/prices/?currency=${Currencies.usd}`,
    );

    return data;
  }

  public async fetchQuotes({
    id,
    range = DEFAULT_QUOTE_RANGE,
  }: FetchQuotesArgs = {}) {
    const { data } = await instance.get<QuoteDTO[]>(
      `market/v1/cryptocurrency/${id}/quotes/?range=${range}&currency=${Currencies.usd}`,
    );

    return data;
  }

  public async addToFavorites(id: Coin["id"]) {
    await authInstance.post(`market/v1/cryptocurrency/${id}/favorites/`);
  }

  public async removeFromFavorites(id: Coin["id"]) {
    await authInstance.delete(`market/v1/cryptocurrency/${id}/favorites/`);
  }

  public async fetchLightCryptocurrencies() {
    const result = await instance.post<ListResult<BaseCoin>>(
      "market/v2/cryptocurrency/light/",
      {
        buyAvailable: "true",
        ordering: "rank",
        pageSize: 1000,
      },
    );

    if (!result.data.results.length) {
      throw new Error("Empty coins list");
    }

    return result.data.results;
  }
})();

export default coinsAPI;
