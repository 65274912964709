/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, {
  InternalAxiosRequestConfig,
  AxiosPromise,
  AxiosResponse as NativeAxiosResponse,
  AxiosError,
  AxiosHeaders,
  AxiosInstance,
  CreateAxiosDefaults,
} from "axios";

import settle from "../../node_modules/axios/lib/core/settle";
import buildURL from "../../node_modules/axios/lib/helpers/buildURL";
import buildFullPath from "../../node_modules/axios/lib/core/buildFullPath";
import utils from "../../node_modules/axios/lib/utils";

import { camelize, snakelize } from "./common";

async function fetchAdapter(config: InternalAxiosRequestConfig): AxiosPromise {
  const request = new AxiosRequest(config);
  const promiseChain = [callRequest(request, config)];
  if (config.timeout) {
    promiseChain.push(
      new Promise(res => {
        setTimeout(() => {
          const message = config.timeoutErrorMessage
            ? config.timeoutErrorMessage
            : "timeout of " + config.timeout + "ms exceeded";

          res(new AxiosError(message, "ECONNABORTED", config, request));
        }, config.timeout);
      }),
    );
  }

  const data = await Promise.race(promiseChain);

  return new Promise((resolve, reject) => {
    if (data instanceof Error) {
      reject(data);
    } else {
      settle(resolve, reject, data);
    }
  });
}

async function callRequest(
  request: AxiosRequest,
  config: InternalAxiosRequestConfig,
) {
  let response: Response;
  try {
    response = await fetch(request);
  } catch {
    return new AxiosError("Network Error", "ERR_NETWORK", config, request);
  }

  return createResponse(request, response, config);
}

async function createResponse<T = any, D = any>(
  request: AxiosRequest<D>,
  response: Response,
  config: InternalAxiosRequestConfig,
): Promise<NativeAxiosResponse<T, D>> {
  const headers = new AxiosHeaders();
  for (const [key, value] of response.headers) {
    headers[key] = value;
  }

  let data: any;
  if (response.status >= 200 && response.status !== 204) {
    switch (config.responseType) {
      case "arraybuffer":
        data = await response.arrayBuffer();
        break;
      case "blob":
        data = await response.blob();
        break;
      case "json":
        data = await response.json();
        break;
      case "document":
        data = await response.formData();
        break;
      default:
        data = await response.text();
        break;
    }
  }

  return {
    status: response.status,
    statusText: response.statusText,
    config,
    headers,
    data,
    request,
  };
}

class AxiosRequest<D = any> extends Request {
  constructor(config: InternalAxiosRequestConfig<D>) {
    const headers = new Headers(config.headers);

    // HTTP basic authentication
    if (config.auth) {
      const username = config.auth.username || "";
      const password = config.auth.password
        ? decodeURI(encodeURIComponent(config.auth.password))
        : "";
      headers.set("Authorization", `Basic ${btoa(username + ":" + password)}`);
    }

    const method = config.method?.toUpperCase();
    const options: RequestInit = {
      headers,
      method,
    };
    if (method !== "GET" && method !== "HEAD") {
      options.body = config.data as BodyInit;

      // In these cases the browser will automatically set the correct Content-Type,
      // but only if that header hasn't been set yet. So that's why we're deleting it.
      if (utils.isFormData(options.body) && utils.isStandardBrowserEnv()) {
        headers.delete("Content-Type");
      }
    }

    // This config is similar to XHR’s withCredentials flag, but with three available values instead of two.
    // So if withCredentials is not set, default value 'same-origin' will be used
    if (!utils.isUndefined(config.withCredentials)) {
      options.credentials = config.withCredentials ? "include" : "omit";
    }

    const fullPath = buildFullPath(config.baseURL, config.url);
    const url = buildURL(fullPath, config.params, config.paramsSerializer);

    super(url, options);
  }
}

export function createAxiosInstance(
  config: Omit<CreateAxiosDefaults, "adapter">,
): AxiosInstance {
  const instance = axios.create({ ...config, adapter: fetchAdapter });

  instance.interceptors.request.use(request => {
    if (request.data) {
      request.data = snakelize(request?.data);
    }

    return request;
  });

  instance.interceptors.response.use(
    response => {
      if (response.data) {
        response.data = camelize(response.data);
      }

      return response;
    },
    error => {
      if (error.response?.data) {
        error.response.data = camelize(error.response.data);
      }

      return Promise.reject(error);
    },
  );

  return instance;
}
