import { AxiosError } from "axios";

type ResponseErrorStatus = "";

interface Errors {
  [key: string]: string[] | Errors;
}

export interface ResponseError {
  status: ResponseErrorStatus;
  detail: string;
  errors: Errors | string | null;
}

const NAMES_WITH_STATUS = {
  401: "Unauthorized",
  404: "NotFound",
  400: "BadRequest",
  500: "ServerError",
};

export class NetworkError<T extends object = ResponseError> extends Error {
  public errors: Partial<Errors> = {};
  public status?: ResponseErrorStatus;
  public responseStatus?: number;

  constructor(axiosError: AxiosError<ResponseError>) {
    const responseData = axiosError.response?.data;
    super(responseData?.detail || axiosError.message);
    if (responseData) {
      this.errors = responseData.errors as Errors;
      this.status = responseData.status;
    }

    this.responseStatus = axiosError.response?.status;
    this.stack = axiosError.stack;

    if (this.responseStatus in NAMES_WITH_STATUS) {
      this.name = NAMES_WITH_STATUS[this.responseStatus];
    }
  }

  public getFieldError(name: keyof T): Errors | string[] | null {
    return this.errors[name] || null;
  }

  public getFirstFieldError(name: keyof T): string | null {
    return this.getFieldError(name)?.[0] || null;
  }
}
